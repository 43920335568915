<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-primary">Enregistrement d'une offre</h4>
              </div>
            </div>
          </div>
          <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />

          <div class="row">
            <div class="col-sm-12">
              <OffreEditorForm ref="offreForm" v-model="offre" :offreId="offreId" />

              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-end my-4">
                    <button
                      @click.prevent="approverOffre"
                      v-if="$can('approve', 'Offre')"
                      type="submit"
                      class="btn btn-outline-primary btn-lg me-3"
                    >
                      Approver pour la publication
                    </button>
                    <button
                      @click.prevent="validerOffrePourApprobation"
                      v-if="$can('validate', 'Offre')"
                      type="submit"
                      class="btn btn-outline-primary btn-lg me-3"
                    >
                      Soumettre pour approbation
                    </button>
                    <button
                      @click.prevent="submitForm"
                      type="submit"
                      class="btn btn-primary btn-lg"
                      :disabled="busy"
                    >
                      > Enrégistrer
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
import OffreEditorForm from "../../../components/espace/entreprise/offre/steps/OffreEditorForm.vue";
// import DisponibiliteActuelle from '@/components/register/offre/steps/DisponibiliteActuelle.vue'

export default {
  props: ["offreId"],
  components: {
    OffreEditorForm,
  },
  data() {
    return {
      offre: {},
      error: false,
      busy: false,
    };
  },
  created() {},
  watch: {
    "offre.phone": {
      handler() {
        this.error = false;
      },
    },
  },
  computed: {},
  methods: {
    ...mapActions({
      createOrUpdateOffre: "offre/createOrUpdateOffre",
    }),
    async approverOffre() {
      this.offre = { ...this.offre, status: "APPROUVE" };
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment approuver le contenu de cette offre?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.saveOffre();
        },
      });
    },
    async validerOffrePourApprobation() {
      this.offre = { ...this.offre, status: "VALIDE" };

      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment soumettre l\'offre pour être publiée par le CA?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.saveOffre();
        },
      });
    },

    async submitForm() {
      let offre = this.offre;
      if (offre.status == "PUBLIE") {
        this.$toast.error(
          "Vous ne pouvez pas modifier une offre déjà publiée (en ligene).\n Si vous tenezDépubliez",
          {
            position: "bottom-right",
            duration: 8000,
          }
        );
        return;
      }

      if (
        !["PUBLIE", "EN_ATTENTE", "NON_APPROUVE"].includes(offre.status) &&
        this.$can("validate", "Offre")
      ) {
        this.$toast.error(`Cette offre a déjà éte ${offre.status} par le CA.`, {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }

      let message = `Les modifications de l'offre seront
       simplement enregistrées sans qu'elle ne soit 
      `;
      if (this.$can("validate", "Offre")) {
        message += `soumise à l\'approbation du CA.\n
          Si vous désirez soumettre l'offre pour approbation, utilisez explicitement le bouton correspondant.
        `;
      }

      if (this.$can("approve", "Offre")) {
        message += `approuvée.\n
          Si vous désirez approuver l\'offre', utilisez explicitement le bouton correspondant.
        `;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: message,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Enrégistrer",
        rejectLabel: "Annuler",
        accept: () => {
          this.saveOffre();
        },
      });
    },
    async saveOffre() {
      if (!(await this.$refs.offreForm.isCompleted())) return;
      this.busy = true;
      this.createOrUpdateOffre(this.offre)
        .then(async (de) => {
          this.$toast.success("Opération réussie!", {
            position: "bottom-right",
            duration: 5000,
          });
          console.log(de);
          this.busy = false;
          this.$router.push({ name: "espace.entreprise.offre.list" });
        })
        .catch(() => {
          this.busy = false;
          this.error = true;
          this.$toast.error(`Erreur d'ajout d'offre.`, {
            position: "bottom-right",
            duration: 35000,
          });
        });
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
